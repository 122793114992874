@import '../base/_settings.scss';

// button types and states
.button {
    padding: 7px 22px;
    border-width: 2px;
    border-style: solid;
    border-color: transparent;
    background-color: transparent;
    cursor: pointer;
    &:disabled {
        cursor: default;
    }
    &.start {
        flex-direction: row;
        justify-content: flex-start;
    }
    &.end {
        flex-direction: row;
        justify-content: flex-end;
    }
}

.button__primary {
    border-color: color(interactive-01);
    background-color: color(interactive-01);
    color: color(text-02);
    svg {
        color: color(text-02);
    }
    &:hover:not([disabled]) {
        border-color: color(interactive-01);
        background-color: transparent;
        color: color(text-03);
        svg {
            color: color(hover-primary-text);
        }
    }
    &:disabled {
        border-color: color(disabled-02);
        background-color: color(disabled-02);
        color: color(text-05);
    }
}

.button__secondary {
    border-color: color(interactive-01);
    color: color(interactive-01);
    &:hover:enabled {
        border-color: color(hover-primary);
        color: color(hover-primary);
    }
    &:disabled {
        border-color: color(disabled-02);
        color: color(disabled-02);
    }
}

.button__terciary {
    color: color(text-03);
    svg {
        color: color(text-03);
    }
    &:hover:enabled {
        color: color(hover-primary);
        svg {
            color: color(hover-primary);
        }
    }
    &:disabled {
        color: color(disabled-02);
    }
}
.button__gold {
    border-color: color(interactive-06);
    background-color: color(interactive-06);
    color: color(icon-01);
    &:hover:enabled {
        border-color: color(interactive-06);
        background-color: color(interactive-06);
    }
    &:disabled {
        border-color: color(disabled-01);
        background-color: color(disabled-01);
        color: color(disabled-03);
    }
}

.button__basic {
    border-color: color(interactive-05);
    background-color: color(interactive-05);
    color: color(icon-01);
    &:hover:enabled {
        border-color: color(interactive-05);
        background-color: color(interactive-05);
    }
    &:disabled {
        border-color: color(disabled-01);
        background-color: color(interactive-05);
        color: color(disabled-03);
    }
}

.button-m {
    border-radius: 48px;
    &:focus-visible {
        border-radius: 48px;
    }
}

.button-l {
    border-radius: 30px;
    &:focus-visible {
        border-radius: 30px;
    }
}

.button__square {
    padding: 10px;
    border-radius: 100%;
    line-height: 0;
    &:focus-visible {
        border-radius: 100%;
    }
}

.select-kids {
    &:hover {
        border-color: #28c2d8 !important;
        background-color: #28c2d8 !important;
    }
}
