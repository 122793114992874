@import '../base/_fontsSize.scss';
@import '../base/_settings.scss';

.input {
    .input-container {
        border-radius: 8px;
        border-width: 2px;
        border-style: solid;
        padding: 6px;
        border-color: color(interactive-02);
        .input-element {
            &:focus-visible {
                outline: 0;
            }
            &:-webkit-autofill {
                -webkit-text-fill-color: color(interactive-02) !important;
            }
        }
        &.focused {
            border-color: color(focus);
        }
    }
}

.input-status-error {
    .input-container {
        border-color: color(support-01);
    }
    span {
        color: color(support-01);
    }
}

.select__container {
    .select__control {
        background-color: transparent;
        border-color: color(interactive-02);
        border-radius: 8px;
        border-width: 2px;
        border-style: solid;
        .select__value-container {
            .select__single-value {
                color: color(text-01);
            }
            .select__placeholder {
                color: color(interactive-03);
            }
            .select__multi-value {
                background-color: transparent !important;
                .select__multi-value__label {
                    color: color(interactive-01);
                }
                .select__multi-value__remove {
                    svg {
                        color: color(interactive-01);
                    }
                }
            }
        }
        &:hover,
        &:focus,
        &:focus-visible {
            border-color: color(focus);
            box-shadow: unset;
        }
    }
}
.select__menu {
    .select__menu-list {
        background-color: transparent !important;
        .select__option--is-focused {
            background-color: color(focus);
        }
    }
}

.select__input {
    color: white !important;
}
