@import '../base/_settings.scss';

.layout__container {
    width: 100%;
}

.layout__container {
    width: 100%;
    height: calc(100vh - 4.5rem);
    overflow-y: scroll;
}

.layout__info-container {
    overflow-y: hidden;
}

.layout__chips-container {
    display: flex;
    overflow-y: auto;
    button {
        white-space: nowrap;
    }
}
