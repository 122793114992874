@import '../base/_settings.scss';

.icon__24{
    width: 20px;
    height: 20px;
    padding: 2px;
}

.icon__36{
    height: 30px;
    width: 30px;
    padding: 3px;
}

.icon__48{
    width: 40px;
    height: 40px;
    padding: 4px;
}

.icon__responsive_24_48{
    @media only screen and (min-width: 550px) {
        width: 48px;
        height: 48px;
        padding: 3px;
     }
     @media only screen and (max-width: 550px) {
        width: 24px;
        height: 24px;
     }
}