@import "../base/_settings.scss";

.footer-container {
    bottom: 0;
    z-index: 100;
    background-color: color(ui-02);
    .footer-row {
        justify-content: space-between;
        min-height: 1.5rem;
    }
}
