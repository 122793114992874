@import '../base/_settings.scss';

.messages__list-container {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 15.25rem);
    overflow-y: auto;
    .messages__list-item {
        display: flex;
        border-bottom: 1px solid color(interactive-01);
        padding: 1rem;
        justify-content: space-between;
        flex-flow: wrap;
        span {
            white-space: nowrap;
        }
        &.read span {
            font-weight: lighter;
        }
        &.active,
        &:hover {
            box-shadow: 0 4px 2px -2px color(interactive-01);
            span {
                color: color(interactive-01);
            }
            path {
                fill: color(interactive-01);
            }
        }
    }
}

.messages__data-container {
    display: flex;
    border-bottom: 1px solid color(interactive-03);
    width: 100%;
    padding: 1rem;
    flex-flow: wrap;
    span {
        font-weight: 800;
    }
    p {
        margin: 0;
        font-weight: 400;
        white-space: nowrap;
    }
}

.messages__body-container {
    padding: 1rem;
    font-weight: lighter;
}
