@import '../base/_settings.scss';

.navbar__container {
    align-items: center;
    display: flex;
    flex-direction: row;
    height: 4.5rem;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 4;

    .header__container {
        align-items: center;
        color: color(text-01);
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .navbar_profile {
            display: flex;
            align-items: center;

            .show-box {
                background-color: color(text-01);
                border-radius: 0px 0px 8px 8px;
                box-shadow: 0px 0px 4px rgba(19, 15, 29, 0.25);
                border-radius: 0px 0px 8px 8px;
                min-width: 212px;
                position: absolute;
                right: 0;
                top: 57px;

                hr {
                    border: 0;
                    display: block;
                    height: 1px;
                    width: 100%;
                    margin-top: -3px;
                }

                .show-box-item {
                    display: flex;
                    justify-content: flex-start;
                    // @include Small;

                    &:hover {
                        // color: $primary;
                        font-weight: bold;
                    }
                }

                .showicon {
                    i {
                        opacity: 0;
                    }

                    &:hover {
                        i {
                            opacity: 1;
                        }
                    }
                }
            }

            img {
                border-radius: 100%;
                height: 40px;
                object-fit: cover;
                width: 40px;
            }
        }
    }
}

.sidebar-width-open {
    --width-sidebar: #{220px};
}

.sidebar-width-close {
    --width-sidebar: #{72px};
}

.aside__width {
    width: var(--width-sidebar);
    transition: width 0.2s ease-out;
    height: calc(100vh - 4.5rem);
    position: sticky;

    @media only screen and (max-width: 768px) {
        z-index: 3;
        position: fixed;
    }
}

.sidebar__container {
    height: 100%;
    width: var(--width-sidebar);
}

.sidebar {
    width: var(--width-sidebar);
    background-color: color(ui-background-02);
    box-shadow: 1px 0px 4px color(interactive-01);
    height: 100%;
    transition: width 0.2s ease-out;
    position: sticky;
    top: 4.5rem;

    .navbar__link-div {
        // color: $text;
        text-decoration: none;

        & > div {
            height: 21px;
        }

        &:hover {
            // background-color: $hover-4;
            // color: $primary;

            svg {
                // color: $primary;
            }
        }
    }

    .link-active {
        p {
            color: color(focus);
        }

        svg {
            color: color(focus);
        }
    }
}

hr {
    width: 85%;
    border: 0;
    border-bottom: 2px solid color(button-separator);
    display: block;
    height: 1px;
}
