input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    box-shadow: 0 0 0px 1000px color(ui-background) inset;
    transition: background-color 5000s ease-in-out 0s;
}

*:focus-visible {
    outline: 2px solid color('interactive-02');
    outline-offset: 6px;
    border-radius: 0;
}

html {
    min-height: 100vh;
    max-height: 100vh;
    height: 100vh;
    overflow-y: auto;
}
body,
#root {
    height: 100%;
}

img {
    object-fit: contain;
}

h1,
h2,
h3,
h4,
p,
span,
label,
input,
svg {
    color: color(text-01);
}

// Height and Width (% and viewport)
@for $i from 1 through 10 {
    .h-#{$i * 10} {
        height: $i * 10%;
    }

    .w-#{$i * 10} {
        width: $i * 10%;
    }

    .vh-#{$i * 10} {
        height: $i * 10vh;
    }

    .vw-#{$i * 10} {
        width: $i * 10vw;
    }
}

// Classes for flex
.center-box {
    align-items: center;
    justify-content: center;
}

.justify-content-end {
    justify-content: end;
}

.space-between {
    justify-content: space-between;
}

.d-flex {
    display: flex;
}

.d-none {
    display: none;
}

.v-hidden {
    visibility: hidden;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

.flex-direction-row {
    flex-direction: row;
}

.flex-direction-column {
    flex-direction: column;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

@for $i from 1 through 48 {
    .gap-#{$i * 2} {
        gap: $i * 2px;
    }
}

@for $i from 1 through 10 {
    .gap-#{$i} {
        gap: $i * 1px;
    }
}

.top-0 {
    top: 0;
}

.bottom-0 {
    bottom: 0;
}

.left-0 {
    left: 0;
}

.right-0 {
    right: 0;
}

@for $i from 1 through 10 {
    .z-index-#{$i * 100} {
        z-index: $i * 100;
    }
}

///////////////////////////////////////////
// Classes for font (colours,positioning)
.width-fit-content {
    width: fit-content;
}

.text-nowrap {
    white-space: nowrap;
}

.text-align-end {
    text-align: end;
}

.text-align-center {
    text-align: center;
}

.text-align-start {
    text-align: start;
}

// Classes for rowMobile

.row__mobile {
    width: 100vw;
    overflow-x: auto;
    white-space: 'nowrap';
}

.row__mobile::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.row__mobile {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
}

//borders
.border-none {
    border: none;
}

.opacity-0 {
    opacity: 0;
}
