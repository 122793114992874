@import '../base/_settings.scss';

.header-container {
    position: sticky;
    top: 0;
    z-index: 100;
    border-bottom: 1px solid color(button-separator);
    background-color: color(ui-02);

    .header-row {
        display: flex;
        justify-content: space-between;

        .header-col-title,
        .header-col-info {
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            height: 48px;
        }

        .vertical-line {
            width: 1px;
            height: 27px;
            background-color: color(button-separator);
        }
    }
}
