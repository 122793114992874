@import '../base/_settings.scss';

.alert__container {
    background-color: color(ui-background-02);
    box-shadow: 0px 0px 8px rgba(12, 14, 23, 0.75);
    box-sizing: border-box;
    display: flex;
    padding: 1rem;
    position: fixed;
    top: 10vh;
    z-index: 4;

    @media only screen and (max-width: 600px) {
        right: 10vh;
        width: 96%;
        margin: 0.2rem;
        border-radius: 8px;
    }
    @media only screen and (min-width: 600px) {
        right: 5rem;
        width: 400px;

        border-radius: 8px;
    }

    &.alert-color-error {
        border: 2px solid color(support-01);
        svg {
            color: color(support-01);
        }
    }

    &.alert-color-success {
        border: 2px solid color(support-02);
        svg {
            color: color(support-02);
        }
    }

    &.alert-color-warning {
        border: 2px solid color(support-03);
        svg {
            color: color(support-03);
        }
    }

    &.alert-color-info {
        border: 2px solid color(support-04);
        svg {
            color: color(support-04);
        }
    }
}
