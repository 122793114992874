$colors: (
    ui-background: #221e1b,
    ui-background-02: #01181a,

    interactive-01: #d0ac6f,
    interactive-02: #ffffff,
    interactive-03: #bbbbbb,

    ui-01: #d0ac6f,
    ui-02: #01181a,
    ui-03: #daeaf9,

    button-separator: #d0ac6f,

    text-01: #ffffff,
    text-02: #0f0f0f,
    text-03: #d0ac6f,

    link-01: #0a5b83,

    icon-01: #ffffff,

    support-01: #f95353,
    support-02: #30c023,
    support-03: #f7bb61,
    support-04: #4783fc,

    fondo-support-01: rgba(249, 83, 83, 0.1),
    fondo-support-02: rgba(78, 228, 65, 0.1),
    fondo-support-03: rgba(210, 126, 0, 0.1),
    fondo-support-04: rgba(30, 68, 146, 0.1),
    overlay-1: rgba(22, 22, 22, 0.5),
    shadow: #fcfcfc,
    focus: #d0ac6f,
    hover-primary: #d49931,
    hover-primary-text: #444242,
    hover-secondary: #4c4c4c,
    highlight: #daeaf9,
    visited-link: #d0ac6f,
    disabled-01: #e4e4e4,
    disabled-02: #acabab,
    disabled-03: #939393,
    active-primary: #1e5d7b,
    active-secondary: #6f6f6f,
    active-tertiary: #1e5d7b,
);

@function color($search) {
    @return map-get($colors, $search);
}

@each $name, $value in $colors {
    .bg-#{$name} {
        background-color: $value;
    }

    .color-#{$name} {
        color: $value;
        path {
            color: $value;
        }
    }

    .border-#{$name} {
        border-color: $value;
    }

    .outline-#{$name} {
        outline-color: $value;
    }
}
